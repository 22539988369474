import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS, MULTIPART_UPLOAD_TYPES } from '@pixcap/ui-core/constants/api.constants';
import { v4 as uuidv4 } from 'uuid';
import axios, { CancelToken } from 'axios';
import { InvalidMultipartUploadError } from '@pixcap/ui-core/errors/InvalidMultipartUploadError';

export interface IMultiUploadService {
	sessionUserId: string;
	multipartUpload(
		blob: Blob,
		type: MULTIPART_UPLOAD_TYPES,
		options?: MultipartUploadOptions,
		cancelToken?: CancelToken,
		callback?: (progress) => void,
		skipErrorNotification?: boolean
	): Promise<void>;
}

export type MultipartUploadOptions = {
	key?: string; //if not provided, uuid generated
	partSize?: number; //if not provided, default to 5500000
	contentType?: string; // if not provided, default to application/octet-stream
	metadata?: Record<string, string>;
};

export class MultiUploadService implements IMultiUploadService {
	sessionUserId = undefined;
	get defaultParams() {
		return this.sessionUserId ? { userId: this.sessionUserId } : {};
	}
	/**
	 * Performs multipart upload using presigned urls
	 * @param file
	 * @param type
	 * @param options
	 */
	async multipartUpload(
		blob: Blob,
		type: MULTIPART_UPLOAD_TYPES,
		options?: MultipartUploadOptions,
		cancelToken?: CancelToken,
		callback?: (progress) => void,
		skipErrorNotification = false
	) {
		const fileSize = blob.size;
		let key = uuidv4();
		let partSize = 5500000;
		let contentType = 'application/octet-stream';
		if (options) {
			if (options.key) key = options.key;
			if (options.partSize) partSize = options.partSize;
			if (options.contentType) contentType = options.contentType;
		}
		const numberOfParts = Math.ceil(fileSize / partSize);

		/**
		 * Multipart upload cannot proceed if uploading empty blob
		 * This can occur with uploading file with invalid location (e.g. drag and drop a file from local machine that no longer exist on location)
		 * or faulty FBX export, etc,
		 */
		if (numberOfParts == 0) {
			throw new InvalidMultipartUploadError('Detected 0 parts to upload.');
		}

		const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;
		const initParams = {
			key,
			numberOfParts,
			type,
			contentType,
			metadata: options?.metadata ? JSON.stringify(options.metadata) : undefined,
			...this.defaultParams,
		};
		const response = await httpClient.get(`${ASSETMANAGER_PATH}/multipart/init`, {
			cancelToken,
			skipErrorNotification,
			params: initParams,
		});
		const { uploadId, presignedUrlsParts } = response.data;
		const promises = [];
		const partsKeys = Object.keys(presignedUrlsParts);

		// Need to create separate axios instance which omits Content-Type from header in order for aws presigned signature to match
		const client = axios.create({
			transformRequest: [
				(data, headers) => {
					delete headers['Content-Type'];
					return data;
				},
			],
		});
		delete client.defaults.headers.common['Content-Type'];
		delete client.defaults.headers.put['Content-Type'];

		const progressByPart = 100 / (partsKeys.length || 1);
		const progresses = new Array(partsKeys.length).fill(0);
		const updateUploadProgress = () => {
			if (callback) {
				const progress = progresses.reduce((a, b) => a + b, 0);
				callback(progress);
			}
		};
		for (const partsKey of partsKeys) {
			const presignedUrl = presignedUrlsParts[partsKey];
			const partNumber = parseInt(partsKey);
			const start = partNumber * partSize;
			const end = (partNumber + 1) * partSize;
			const blobPart = partNumber < partsKeys.length ? blob.slice(start, end) : blob.slice(start);
			promises.push(
				client.put(presignedUrl, blobPart, {
					onUploadProgress: (progressEvent) => {
						const progress = Math.floor((progressEvent.loaded / progressEvent.total) * progressByPart);
						progresses[partNumber] = progress;
						updateUploadProgress();
					},
				})
			);
		}

		const resParts = await Promise.all(promises);
		const parts = resParts.map((part, index) => ({
			etag: part.headers.etag,
			partNumber: index + 1,
		}));

		const multipartCompleteDto = {
			key,
			uploadId,
			parts,
			...this.defaultParams,
		};

		await httpClient.post(`${ASSETMANAGER_PATH}/multipart/complete`, multipartCompleteDto, {
			cancelToken,
			skipErrorNotification,
			params: {
				type,
			},
		});
	}
}
