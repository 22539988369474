import type { Mixpanel } from 'mixpanel-browser';
import type { IPixcapServices } from '@pixcap/ui-core/services';
import mixpanel from '@pixcap/ui-core/modules/Mixpanel';
import { IPixcapWidgets } from '@pixcap/ui-core/widgets';
export interface IAppUtilities<IServices = IPixcapServices, IWidgets = IPixcapWidgets> {
	$mixpanel?: Mixpanel;
	$services?: IServices;
	$widgets?: IWidgets;
	$engineBridge?: any;
	$crisp?: any;
	$GTM_dataLayer?: any;
	$metaPixel?: any;
	$setUserForErrorTracker: (...args) => void;
	$captureError: (...args) => void;
}

export class AppUtilities implements IAppUtilities {
	$mixpanel = null;
	$services = null;
	$widgets = null;
	$engineBridge = null;
	$GTM_dataLayer?: any;
	$setUserForErrorTracker = (...args) => {};
	$captureError = (...args) => {};

	constructor() {
		this.$mixpanel = mixpanel;
	}
}

let appUtilities: IAppUtilities = null;

function setAppUtilities(appUtilitiesParam) {
	appUtilities = appUtilitiesParam;
}

export { appUtilities, setAppUtilities };
