import type { IMultiUploadService } from '@pixcap/ui-core/services/MultiUploadService';
import { MultiUploadService } from '@pixcap/ui-core/services/MultiUploadService';
import { LocalStorageManager } from '@pixcap/ui-core/services/localStorageManager';
import type { ILocalStorageManager } from '@pixcap/ui-core/services/localStorageManager';
import type { IAuthService } from '@pixcap/ui-core/services/AuthService';
import { AuthService } from '@pixcap/ui-core/services/AuthService';
import type { IServiceWorkerManager } from '@pixcap/ui-core/models/services/serviceWorkerManager.interface';
import type { ICachePresignedUrlsManager } from '@pixcap/ui-core/services/syncmanager/CachePresignedUrlsManager';
import { ServerRenderProgressSimulator } from '@pixcap/ui-core/services/ServerRenderProgressSimulator';
import ServerRenderManager from '@pixcap/ui-core/services/ServerRenderManager';
export interface IPixcapServices {
	multiUploadService: IMultiUploadService;
	localStorageManager: ILocalStorageManager;
	authService: IAuthService;
	serviceWorkerManager?: IServiceWorkerManager;
	cacheUrlsManager: ICachePresignedUrlsManager;
	serverRenderProgressSimulator?: ServerRenderProgressSimulator;
	idleRenderManager?: ServerRenderManager;
}

export class PixcapServices implements IPixcapServices {
	cacheUrlsManager = null;
	localStorageManager = null;
	multiUploadService = null;
	authService = null;
	serverRenderProgressSimulator = null;
	constructor() {
		this.localStorageManager = new LocalStorageManager();
		this.multiUploadService = new MultiUploadService();
		this.authService = new AuthService();
		this.serverRenderProgressSimulator = new ServerRenderProgressSimulator();
	}
}
