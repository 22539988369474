import { SortBy } from '@pixcap/ui-core/models/store/app.interface';

export enum RESOLUTION_TYPE {
	CUSTOM = 'CUSTOM',
	VIDEO = 'VIDEO',
	FACEBOOK_POST = 'FACEBOOK_POST',
	INSTAGRAM_POST = 'INSTAGRAM_POST',
	INSTAGRAM_STORY = 'INSTAGRAM_STORY',
	FACEBOOK_VIDEO = 'FACEBOOK_VIDEO',
	TWITTER_POST = 'TWITTER_POST',
}

export enum ProjectTypes {
	ANIMATED = 'animated',
	STATIC = 'static',
}

export enum VIDEO_FORMAT {
	WEBM = 'webm',
	MP4 = 'mp4',
}

export enum EXPORT_FORMAT {
	JPG = 'jpg',
	PNG = 'png',
	GIF = 'gif',
	VIDEO = 'video',
}

export enum PROJECT_TYPES {
	FBX = 'FBX',
	GLB = 'GLB',
}

export enum EXPORT_TYPES {
	ALL_ANIMATION_LAYERS = 'ALL_ANIMATION_LAYERS',
	SINGLE_ANIMATION_LAYER = 'SINGLE_ANIMATION_LAYER',
}

export type FBXExportFile = {
	exportName?: string;
	exportId?: string;
	fbxFileId?: string;
	projectId?: string;
	isFailed?: boolean;
	errorMessage?: string;
};

export enum ExportDownloadType {
	DEFAULT = 'default',
	HIGH_QUALITY = 'high_quality',
	AI_GENERATED = 'ai_generated',
}

export interface ExportDownloadItem extends PixRenderExportPayload {
	type: ExportDownloadType;
}

export enum ExportModalTab {
	IMAGE = 'Image',
	VIDEO = 'Video',
	GIF = 'GIF',
	FILE = 'File',
}

export enum PixRenderProgressStatus {
	INIT = 'init', // 0  -> 10
	STARTING = 'starting', // 10 -> 12
	STARTED = 'started', // 12 -> 13
	DOWNLOAD = 'download', // 15 -> 17
	RUNNING = 'running', // 20 -> 69
	COMPLETE = 'complete', // 69 -> 70
	STITCHING = 'stitching',
	UPLOAD = 'upload', // 70 -> 75
	MERGING = 'merging', //  75 -> 80
	THUMBNAIL = 'thumbnail', // 80 -> 85
	FINISH = 'finish', // 85 -> 100
	CANCELLED = 'cancelled', // xx -> 90
	ERROR = 'error', // xx -> 90
	FAILED = 'failed', // xx -> 100
	REMOVED = 'removed', // xx -> 100
	DONE = 'finishWithOutDownload', // xx -> 100
}

export enum PixRenderExportStatus {
	PREPARING = 'PREPARING',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
	DONE = 'DONE',
	FAILED = 'FAILED',
	REMOVED = 'REMOVED',
}

export enum PixRenderExportType {
	IMAGE = 'image',
	VIDEO = 'video',
	GIF = 'gif',
	THREE_D = '3d',
}

export enum PixRenderFormat {
	PNG = 'PNG',
	JPEG = 'JPEG',
	FFMPEG = 'FFMPEG',
	MP4 = 'MP4',
	GIF = 'GIF',
	WEBM = 'WEBM',
	GLB = 'GLB',
	GLTF = 'GLTF',
}

export interface PixRenderExportPayload {
	userId?: string;
	renderId?: string;
	fileId?: string;
	status?: PixRenderExportStatus;
	description?: string;
	progress?: number;
	prompt?: string;
	fileName?: string;
	format: PixRenderFormat;
	downloaded?: boolean;
	size?: string;
	resolution?: string;
	animationUrl?: string;
	thumbnailUrl?: string;
	backgroundRemoved?: boolean;
	exportType: PixRenderExportType;
	updateDate?: string;
	animationDuration?: number; // used only for client side
	isPreviewFileRender?: boolean;
	defaultDownloadUrl?: any;
}

export type ExportItemsFilter = {
	search?: string;
	sortBy?: SortBy;
	exportDownloadType?: ExportDownloadType;
	exportStatus?: PixRenderExportStatus;
	exportItemType?: ProjectTypes;
	currentPage?: number;
};

export enum EFFECT_IMAGE_TYPE {
	FOREGROUND = 'foreground',
	BACKGROUND = 'background',
}

export type BackgroundExportOptions = {
	color?: string;
	image?: string;
	cropBox?: {
		x: number;
		y: number;
		w: number;
		h: number;
	};
	crop?: {
		height: number;
		width: number;
		start_x: number;
		start_y: number;
		left_padding_x: number;
		right_padding_x: number;
		top_padding_y: number;
		bottom_padding_y: number;
	};
};

export type EffectExportOptions = {
	url?: string;
	type?: EFFECT_IMAGE_TYPE;
};

export const NAMESPACE = 'projectexports';

export interface IExportState {
	showExportFBXModal: boolean;
	showExportImageModal: boolean;
	exportVideoModal?: IExportVideoPayload;
	exportGifModal?: IExportVideoPayload;
	showExportProjectModal: PROJECT_TYPES;
	exportedFBXFiles: FBXExportFile[];
	isExportingVideo: boolean;
	exportingVideoProgress: { progress: number; message: string };
	exportVideoPayload: IExportVideoPayload;
	exportModalActiveTab?: ExportModalTab; // if null so don't show export modal
	exportItemsFilter: ExportItemsFilter;
	serverExportItems: PixRenderExportPayload[];
	isFetchingExportDownloadItems: boolean;
	exportDownloadItems: ExportDownloadItem[];
	totalExportDownloadItems: number;
	currentPage: number;
	totalPages: number;
	selectedServerExportItem?: string;
	serverExportItemToCancel?: string;
}

export interface IExportVideoPayload {
	title: string;
	startFrame: number;
	endFrame: number;
	fps: number;
	disableVideoExport?: boolean;
	thumbnailUrl?: string;
}

export type ExportImageWithTransformation = {
	imageBlob: Blob;
	filename: string;
	width?: number;
	height?: number;
	isPng: boolean;
	transparentBackground: boolean;
	isUpscale?: boolean;
	isRemoveBackground?: boolean;
	isDownload?: boolean;
};

export type ExportAIStyledImageWithTransformation = {
	presignedUrl: string;
	filename: string;
	imageStyleId?: string;
	width?: number;
	height?: number;
	isPng: boolean;
	transparentBackground: boolean;
	isUpscale?: boolean;
	isRemoveBackground?: boolean;
};

export type Getters<S = IExportState> = {
	[GetterTypes.IS_SERVER_EXPORT_LIMIT_EXCEEDED]: (state: S) => boolean;
};

export enum GetterTypes {
	IS_SERVER_EXPORT_LIMIT_EXCEEDED = 'isServerExportLimitExceeded',
}

export enum MutationTypes {
	SET_SHOW_EXPORT_MODAL = 'SET_SHOW_EXPORT_MODAL',
	SET_SHOW_EXPORT_IMAGE_MODAL = 'SET_SHOW_EXPORT_IMAGE_MODAL',
	SET_EXPORT_VIDEO_MODAL = 'SET_EXPORT_VIDEO_MODAL',
	SET_EXPORT_GIF_MODAL = 'SET_EXPORT_GIF_MODAL',
	SET_SHOW_EXPORT_PROJECT_MODAL = 'SET_SHOW_EXPORT_PROJECT_MODAL',
	SET_EXPORTED_FBX_FILES = 'SET_EXPORTED_FBX_FILES',
	UPDATE_EXPORT_FBX_FILE = 'UPDATE_EXPORT_FBX_FILE',
	DELETE_FBX_EXPORT_FILE = 'DELETE_FBX_EXPORT_FILE',
	SET_IS_EXPORTING_VIDEO = 'SET_IS_EXPORTING_VIDEO',
	UPDATE_EXPORTING_VIDEO_STATUS = 'UPDATE_EXPORTING_VIDEO_STATUS',
	///////
	SET_VIDEO_EXPORT_PAYLOAD = 'SET_VIDEO_EXPORT_PAYLOAD',
	SET_EXPORT_MODAL_ACTIVE_TAB = 'SET_EXPORT_MODAL_ACTIVE_TAB',
	RESET_SERVER_EXPORT_ITEMS = 'RESET_SERVER_EXPORT_ITEMS',
	SET_EXPORT_ITEMS_FILTER = 'SET_EXPORT_ITEMS_FILTER',
	ADD_OR_UPDATE_SERVER_EXPORT_ITEM = 'ADD_OR_UPDATE_SERVER_EXPORT_ITEM',
	REMOVE_SERVER_EXPORT_ITEM = 'REMOVE_SERVER_EXPORT_ITEM',
	SET_IS_FETCHING_EXPORT_DOWNLOADS = 'SET_IS_FETCHING_EXPORT_DOWNLOADS',
	SET_EXPORT_DOWNLOADS = 'SET_EXPORT_DOWNLOADS',
	ADD_OR_UPDATE_EXPORT_DOWNLOAD_ITEM = 'ADD_OR_UPDATE_EXPORT_DOWNLOAD_ITEM',
	REMOVE_EXPORT_DOWNLOAD_ITEM = 'REMOVE_EXPORT_DOWNLOAD_ITEM',
	RESET_EXPORT_DOWNLOADS = 'RESET_EXPORT_DOWNLOADS',
	SET_SELECTED_SERVER_EXPORT_ITEM = 'SET_SELECTED_SERVER_EXPORT_ITEM',
	SET_SERVER_EXPORT_ITEM_TO_CANCEL = 'SET_SERVER_EXPORT_ITEM_TO_CANCEL',
}

export enum ActionTypes {
	EXPORT_VIDEO = 'EXPORT_VIDEO',
	EXPORT_GIF = 'EXPORT_GIF',
	EXPORT_VIDEO_OR_GIF = 'EXPORT_VIDEO_OR_GIF',
	EXPORT_IMAGE = 'EXPORT_IMAGE',
	EXPORT_SELECTED_IMAGE = 'EXPORT_SELECTED_IMAGE',
	FETCH_FBX_FILES = 'FETCH_FBX_FILES',
	CANCEL_EXPORT_FBX = 'CANCEL_EXPORT_FBX',
	DOWNLOAD_FBX_FILE = 'DOWNLOAD_FBX_FILE',
	SUBSCRIBE_TO_EXPORT_FBX_READY = 'SUBSCRIBE_TO_EXPORT_FBX_READY',
	EXPORT_FBX = 'EXPORT_FBX',
	EXPORT_PROJECT = 'EXPORT_PROJECT',
	CHECK_CAN_EXPORT_3D_FILE = 'CHECK_CAN_EXPORT_3D_FILE',
	SUBSCRIBE_TO_SERVER_RENDER_PROGRESS = 'SUBSCRIBE_TO_SERVER_RENDER_PROGRESS',
	SERVER_RENDER = 'SERVER_RENDER',
	FETCH_EXPORT_DOWNLOADS = 'FETCH_EXPORT_DOWNLOADS',
	DOWNLOAD_EXPORT_DOWNLOAD = 'DOWNLOAD_EXPORT_DOWNLOAD',
	DOWNLOAD_AI_GENERATED_FILE = 'DOWNLOAD_AI_GENERATED_FILE',
	DOWNLOAD_SERVER_RENDER_FILE_BY_RENDER_ID = 'DOWNLOAD_SERVER_RENDER_FILE_BY_RENDER_ID',
	DELETE_SERVER_RENDER_FILE = 'DELETE_SERVER_RENDER_FILE',
	RETRY_SERVER_RENDER_EXPORT = 'RETRY_SERVER_RENDER_EXPORT',
	CANCEL_SERVER_RENDER_EXPORT = 'CANCEL_SERVER_RENDER_EXPORT',
	EXPORT_IMAGE_WITH_TRANSFORMATION = 'EXPORT_IMAGE_WITH_TRANSFORMATION',
	EXPORT_AI_STYLED_IMAGE_WITH_TRANSFORMATION = 'EXPORT_AI_STYLED_IMAGE_WITH_TRANSFORMATION',
	POPULATE_IMAGE_TO_MY_DOWNLOADS = 'POPULATE_IMAGE_TO_MY_DOWNLOADS',
	PUBLISH_SCENE_TO_COMMUNITY = 'PUBLISH_SCENE_TO_COMMUNITY',
	PUBLISH_AI_SCENE_TO_COMMUNITY = 'PUBLISH_AI_SCENE_TO_COMMUNITY',
	COUNT_PROJECT_EXPORT = 'COUNT_PROJECT_EXPORT',
	POLL_ONGOING_SERVER_EXPORT_STATUS = 'POLL_ONGOING_SERVER_EXPORT_STATUS',
	REMOVE_IMAGE_BACKGROUND = 'REMOVE_IMAGE_BACKGROUND',
}

export enum ShadowSoftness {
	HARD = 'hard',
	MEDIUM = 'medium',
	SOFT = 'soft',
}
