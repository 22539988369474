import { FreebiesTypes, SortBy } from '@pixcap/ui-core/models/store/app.interface';
import { LIBRARY_ITEM_TYPE } from '@pixcap/ui-types/enums/library.enums';

export enum UserRoles {
	CONTRIBUTOR = 'CONTRIBUTOR',
	REVIEWER = 'REVIEWER',
}
export enum UserOnboardingSteps {
	ELEMENTS = 'elements',
	MATERIALS = 'materials',
	AI_STYLE = 'aiStyle',
	PRESET = 'preset',
	EDUCATE_EXPORT = 'educateExport',
	UPDATE_MOCKUP_IMAGE = 'useMockup',
}

export enum TemplateImportOption {
	NEW_TAB = 'NEW_TAB',
	CURRENT_TAB = 'CURRENT_TAB',
}
export type User = {
	userId: string;
	name: string;
	email: string;
	avatarUrl: string | null;
	bio?: string;
	isFederated: boolean;
	roles: UserRoles[];
	limits: {
		credits: {
			monthlyLimit: number;
			monthlyUsed: number;
			topped: number;
		};
		exports3D: {
			canRun: boolean;
			remaining: number;
		};
	};
	onboarding: { [step in UserOnboardingSteps]?: boolean };
	metadata: {
		showPixrenderPopup?: boolean;
		surveySubmitted?: boolean;
		projectExported?: boolean;
		projectCreated?: boolean;
		aiAttempted?: boolean;
		isBetaUser?: boolean;
		openProject?: TemplateImportOption;
	};
	projectCount: number;
	purchaseHistory: string[];
};

export type PersonalData = {
	// projects: string[];
	projectLikes: string[];
	user: User;
};

export enum PlanRanges {
	MONTHLY = 'Monthly',
	ANNUALLY = 'Annually',
}

export enum PORTAL_PAYMENT_STATUS {
	SUCCESS = 'complete',
	CANCEL = 'cancelled',
}

export enum ContactModalType {
	SHOW_FOR_PRICING = 'SHOW_FOR_PRICING',
	DEFAULT = 'DEFAULT',
}

export enum MY_ACCOUNT_TAB {
	ACCOUNT_SETTINGS = 'Account settings',
	INVITE_FRIENDS = 'Invite friends',
	SUBSCRIPTION = 'Subscription',
	PROMOTIONS = 'Promotions',
	CHANGE_PASSWORD = 'Change Password',
	EMAIL_NOTIFICATIONS = 'Email Notifications',
}

export enum USER_NOTIFICATION_TYPE {
	SYSTEM_NEW_FEATURE_RELEASED = 'SYSTEM_NEW_FEATURE_RELEASED',
	USER_STYLE_LIKED = 'USER_STYLE_LIKED',
	SYSTEM_PACK_PUBLISHED = 'SYSTEM_PACK_PUBLISHED',
	SYSTEM_TEMPLATE_PUBLISHED = 'SYSTEM_TEMPLATE_PUBLISHED',
	SYSTEM_PROJECT_NOT_EXPORTED = 'SYSTEM_PROJECT_NOT_EXPORTED',
}

export enum SUBSCRIPTION_UPGRADE_CASE {
	TOP_UP_CREDITS = 'TOP_UP_CREDITS',
	HIT_CREDIT_LIMIT = 'HIT_CREDIT_LIMIT',
	USE_PRO_ITEM = 'USE_PRO_ITEM',
	USE_PREMIUM_FEATURE = 'USE_PREMIUM_FEATURE',
	ADD_FAST_SUPER_DOWNLOAD = 'ADD_FAST_SUPER_DOWNLOAD',
	USE_PROMOTION = 'USE_PROMOTION',
	USE_AI_PROMPT = 'USE_AI_PROMPT',
	USE_REMOVE_WATERMARK = 'USE_REMOVE_WATERMARK',
}

export enum UPGRADE_PLAN_TRIGGER_TYPE {
	AI_STYLIST_EXCEED_LIMIT = 'AI Stylist Exceed Limit',
	RECHARGE_CREDITS = 'Recharge Credits',
	DOWNLOAD_EXCEED_LIMIT = 'Download Exceed Limit',
	CUSTOM_AI_PROMPT = 'Custom AI prompt',
	PRO_ITEM_USE = 'Use PRO Library Item',
	GLB_DOWNLOAD = 'GLB Download',
	HIGH_QUALITY_DOWNLOAD = '4K Download',
	REMOVE_BACKGROUND = 'Remove background',
	REMOVE_WATERMARK = 'Remove watermark',
	FASTER_RENDER = 'Faster render',
	REFERRAL_PROMOTION = 'Referral promotion',
	WELCOME_PROMOTION = 'Welcome promotion',
	CUSTOM_BOARD_SIZE = 'Custom board size',
	UPSCALE = 'Upscale',
}

export enum PaymentChannel {
	STRIPE = 'STRIPE',
	PAYPAL = 'PAYPAL',
	XENDIT = 'XENDIT',
	TAZAPAY = 'TAZAPAY',
	PADDLE = 'PADDLE',
}

export enum ReferralType {
	REFEREE = 'referee',
	REFERRAL = 'referral',
}

export enum CartItemType {
	PACK = 'PACK',
	ITEM = 'ITEM',
}

export type SubscriptionInfo = {
	subscription: SUBSCRIPTION | null;
	paymentStatus: PAYMENT_STATUS | null;
	currentPeriodEnd: string;
	isFreeTrialInitiated: boolean;
	channel?: PaymentChannel;
	price?: string | null;
	pricingCountryCode: string | null;
};

export type ReferralsInfo = {
	referralCode: string;
	rewards: { name: FreebiesTypes; source: string; type: ReferralType }[];
};

export type EmailNotifications = { [k in UNSUBSCRIBE_EMAIL_OPTIONS]: boolean };

export type Notification = {
	userNotificationId: string;
	notificationType: USER_NOTIFICATION_TYPE;
	notificationText: string;
	displayName: string;
	avatarUrl: string;
	notificationIcons: [{ url: string; icon: string }];
	displayTime: string;
	createdAt: string;
};

export enum SUBSCRIPTION {
	BASIC = 'BASIC',
	PRO_MONTHLY = 'PRO_MONTHLY',
	PRO_QUARTERLY = 'PRO_QUARTERLY',
	PRO_ANNUALLY = 'PRO_ANNUALLY',
	ELITE_MONTHLY = 'ELITE_MONTHLY',
	ELITE_QUARTERLY = 'ELITE_QUARTERLY',
	ELITE_ANNUALLY = 'ELITE_ANNUALLY',
}

export enum SUBSCRIPTION_TYPE {
	BASIC = 'BASIC',
	PROFESSIONAL = 'PROFESSIONAL',
	ELITE = 'ELITE',
	ENTERPRISE = 'ENTERPRISE',
}

// for BASIC subscription it's always null
export enum PAYMENT_STATUS {
	ACTIVE = 'ACTIVE',
	CANCELLED = 'CANCELLED',
	PAYMENT_ACTION_REQUIRED = 'PAYMENT_ACTION_REQUIRED',
	PAYMENT_REQUIRES_METHOD = 'PAYMENT_REQUIRES_METHOD',
	FREE_TRIAL = 'FREE_TRIAL',
	FREE_TRIAL_CANCELLED = 'FREE_TRIAL_CANCELLED',
	PENDING_RENEWAL = 'PENDING_RENEWAL',
}

export type BaseUserInfo = {
	userId: string;
	displayName: string;
	email: string;
};

export enum UNSUBSCRIBE_EMAIL_OPTIONS {
	ALL = 'ALL',
	DOWNLOADS = 'DOWNLOADS',
	EVENTS = 'EVENTS',
	NEWSLETTERS = 'NEWSLETTERS',
	REMINDERS = 'REMINDERS',
	COMMENTS = 'COMMENTS',
	THREAD = 'THREAD',
	MENTION_REPLY = 'MENTION_REPLY',
	PROJECT_INVITATION = 'PROJECT_INVITATION',
	ACCEPTED_INVITATION = 'ACCEPTED_INVITATION',
}

export interface IEmailNotificationOption {
	title?: string;
	description: string;
}

export type TEmailNotificationOptionKey = UNSUBSCRIBE_EMAIL_OPTIONS;

export type TEmailNotificationOptions = Map<TEmailNotificationOptionKey, IEmailNotificationOption>;

type CREDIT_BUNDLE = {
	CREDITS: number;
	PRICE: number;
};

export type CartItem = {
	currencyCode: string;
	name: string;
	packCount?: number;
	packId?: string;
	itemId?: string;
	itemType?: LIBRARY_ITEM_TYPE;
	itemTypeId?: string;
	animationDuration?: number;
	dimension?: string;
	price: number;
	thumbnailUrl: string | { url: string; colour?: string; format?: string; width?: number; height?: number };
};

export type LocalisedPricing = {
	CURRENCY_PREFIX: string;
	CURRENCY_CODE: string;
	PRO_MONTH?: number;
	PRO_QUARTER?: number;
	PRO_ANNUAL: number;
	ELITE_MONTH?: number;
	ELITE_QUARTER?: number;
	ELITE_ANNUAL: number;
	CREDIT_BUNDLES: CREDIT_BUNDLE[];
};

export type PurchaseItemsFilter = {
	search?: string;
	sortBy?: SortBy;
	currentPage?: number;
};

export type PurchaseItem = {
	orderNumber: string;
	itemId: string;
	itemName: string;
	purchaseDate: string;
	packs?: {
		packId: string;
		packName: string;
	};
	thumbnailUrl?: { url: string; colour?: string; width?: number; height?: number; format?: string };
	animationUrl?: { url: string; colour?: string; width?: number; height?: number; format?: string };
};

export type UserState = {
	personalData: PersonalData;
	userId: string | null;
	userCountry: string | null;
	subscriptionInfo: SubscriptionInfo;
	referralsInfo: ReferralsInfo;
	emailNotifications: EmailNotifications;
	notifications: {
		content: Notification[];
		page: number;
		size: number;
		totalItems: number;
		totalPages: number;
	};
	showCancelPlanModal: boolean;
	showAccountSettingModal: boolean;
	showReferralModal: boolean;
	showReferralPopoverModal: boolean;
	activeMyAccountTab: MY_ACCOUNT_TAB;
	CroppPictureModal: any;
	isLoadingUserInfo: boolean;
	showNoAuthModal: boolean;
	showResetPasswordModal: boolean;
	upgradeModalState: {
		triggerCase?: SUBSCRIPTION_UPGRADE_CASE;
		initPlan?: SUBSCRIPTION_TYPE;
		triggerType?: UPGRADE_PLAN_TRIGGER_TYPE;
	};
	showTopUpCreditsModal: boolean;
	toppedCreditsQuantityForPayment: number;
	showPortalPaymentStatus: {
		status: PORTAL_PAYMENT_STATUS;
		subscription?: SUBSCRIPTION;
		topUpCreditsQuantity?: number;
		initialCredits?: number;
		channel: PaymentChannel;
		isTopUpEvent: boolean;
		isBuyItemEvent: boolean;
	};
	selectedSubscriptionForPayment: SUBSCRIPTION;
	showContactModal: ContactModalType;
	isSendingMessage: boolean;
	showAttributionDownloadModal: { isAIDownload?: boolean; thumbnailUrl?: string };
	isFetchingCartItems: boolean;
	cartItems: CartItem[];
	cartItemsId: { itemId: string; itemType: CartItemType }[];
	showCartPaymentMethodModal: {
		isOpen: boolean;
		items?: { itemId: string; itemType: CartItemType }[];
	};
	showCartPaymentStatusPopoverModal: {
		itemsId?: string[];
		packsId?: string[];
	};

	// Purchase History
	isFetchingPurchaseItems: boolean;
	purchaseItemFilter: PurchaseItemsFilter;
	purchaseItems: PurchaseItem[];
	purchaseCurrentPage: 0;
	purchaseTotalPages: null;
};

// store part
export const NAMESPACE = 'user';

export type Getters<S = UserState> = {
	userId(state: S): string | null;
	getPricingForUser(state: S): LocalisedPricing;
	overridingPricingCountryCode(state: S): string;
	showMyAccountModal(state: S): boolean;
	isPro(state: S): boolean;
	isElite(state: S): boolean;
	isProOrAbove(state: S): boolean;
	isEliteOrAbove(state: S): boolean;
	isSubscribedWithStripe(state: S): boolean;
	isSubscribedWithPaypal(state: S): boolean;
	isSubscribedWithXendit(state: S): boolean;
	isSubscribedWithTazapay(state: S): boolean;
	isSubscribedWithPaddle(state: S): boolean;
	isFreeTrialAvailable(state: S): boolean;
	isFreeTrialActive(state: S): boolean;
	showFreeTrialPaymentInfo(state: S, getters: { [K in keyof Getters]: ReturnType<Getters[K]> }): boolean;
	isUnsubscribedUser(state: S): boolean;
	isReferralUser(state: S): boolean;
	isCanceledSubscription(state: S): boolean;
	subscriptionText(state: S): string;
	isContributor(state: S): boolean;
	isReviewer(state: S): boolean;
	[GetterTypes.HAS_NO_UPGRADE_OPTION](state: S): boolean;
	[GetterTypes.GET_AVAILABLE_CREDITS](state: S): number;
};

export enum GetterTypes {
	USER_ID = 'userId',
	GET_PRICING_FOR_USER = 'getPricingForUser',
	OVERRIDING_PRICING_COUNTRY_CODE = 'overridingPricingCountryCode',
	SHOW_MY_ACCOUNT_MODAL = 'showMyAccountModal',
	SUBSCRIPTION_TEXT = 'subscriptionText',
	IS_PRO = 'isPro',
	IS_ELITE = 'isElite',
	IS_PRO_OR_ABOVE = 'isProOrAbove',
	IS_ELITE_OR_ABOVE = 'isEliteOrAbove',
	HAS_NO_UPGRADE_OPTION = 'hasNoUpgradeOption',
	IS_SUBSCRIBED_WITH_STRIPE = 'isSubscribedWithStripe',
	IS_SUBSCRIBED_WITH_PAYPAL = 'isSubscribedWithPaypal',
	IS_SUBSCRIBED_WITH_XENDIT = 'isSubscribedWithXendit',
	IS_SUBSCRIBED_WITH_TAZAPAY = 'isSubscribedWithTazapay',
	IS_SUBSCRIBED_WITH_PADDLE = 'isSubscribedWithPaddle',
	IS_FREE_TRIAL_AVAILABLE = 'isFreeTrialAvailable',
	IS_FREE_TRIAL_ACTIVE = 'isFreeTrialActive',
	IS_CANCELED_SUBSCRIPTION = 'isCanceledSubscription',
	SHOW_FREE_TRIAL_PAYMENT_INFO = 'showFreeTrialPaymentInfo',
	GET_AVAILABLE_CREDITS = 'getAvailableCredits',
	IS_UNSUBSCRIBED_USER = 'isUnsubscribedUser',
	IS_CONTRIBUTOR = 'isContributor',
	IS_REVIEWER = 'isReviewer',
}

export enum MutationTypes {
	SET_USER_ID = 'SET_USER_ID',
	UPDATE_PERSONAL_DATA = 'UPDATE_PERSONAL_DATA',
	UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR',
	UPDATE_SHOW_PIXRENDER_POPUP = 'UPDATE_SHOW_PIXRENDER_POPUP',
	UPDATE_USER_ONBOARDING = 'UPDATE_USER_ONBOARDING',
	UPDATE_USER_AI_ATTEMPTED = 'UPDATE_USER_AI_ATTEMPTED',
	UPDATE_IMPORT_TEMPLATE_OPTION = 'UPDATE_IMPORT_TEMPLATE_OPTION',
	UPDATE_PROJECT_COUNT = 'UPDATE_PROJECT_COUNT',
	TOGGLE_UPGRADE_MODAL = 'TOGGLE_UPGRADE_MODAL',
	TOGGLE_NO_AUTH_MODAL = 'TOGGLE_NO_AUTH_MODAL',
	TOGGLE_RESET_PASSWORD_MODAL = 'TOGGLE_RESET_PASSWORD_MODAL',
	SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO',
	SET_CREDITS_INFO = 'SET_CREDITS_INFO',
	SET_REFERRALS_INFO = 'SET_REFERRALS_INFO',
	SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS',
	PUSH_USER_NOTIFICATIONS = 'PUSH_USER_NOTIFICATIONS',
	TOGGLE_CANCEL_PLAN_MODAL = 'TOGGLE_CANCEL_PLAN_MODAL',
	TOGGLE_ACCOUNT_SETTING_MODAL = 'TOGGLE_ACCOUNT_SETTING_MODAL',
	SET_ACTIVE_MY_ACCOUNT_TAB = 'SET_ACTIVE_MY_ACCOUNT_TAB',
	UPDATE_BIO = 'UPDATE_BIO',
	UPDATE_DISPLAY_NAME = 'UPDATE_DISPLAY_NAME',
	UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS',
	UPDATE_PROJECT_LIKES = 'UPDATE_PROJECT_LIKES',
	TOGGLE_CROPP_AVATAR_MODAL = 'TOGGLE_CROPP_AVATAR_MODAL',
	SHOW_PORTAL_PAYMENT_STATUS_MODAL = 'SHOW_PORTAL_PAYMENT_STATUS_MODAL',
	SET_IS_LOADING_USER_INFO = 'SET_IS_LOADING_USER_INFO',
	SET_UPGRADE_MODAL_STATE = 'SET_UPGRADE_MODAL_STATE',
	SET_SHOW_TOP_UP_CREDITS_MODAL = 'SET_SHOW_TOP_UP_CREDITS_MODAL',
	SET_TOPPED_CREDITS_QUANTITY_FOR_PAYMENT = 'SET_TOPPED_CREDITS_QUANTITY_FOR_PAYMENT',
	SET_SHOW_CONTACT_MODAL = 'SET_SHOW_CONTACT_MODAL',
	SET_SHOW_REFERRAL_MODAL = 'SET_SHOW_REFERRAL_MODAL',
	SET_SHOW_REFERRAL_POPOVER_MODAL = 'SET_SHOW_REFERRAL_POPOVER_MODAL',
	SET_IS_SENDING_MESSAGE = 'SET_IS_SENDING_MESSAGE',
	SET_EMAIL_NOTIFICATIONS = 'SET_EMAIL_NOTIFICATIONS',
	SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT = 'SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT',
	SET_SHOW_ATTRIBUTION_EXPORT_MODAL = 'SET_SHOW_ATTRIBUTION_EXPORT_MODAL',
	SET_USER_COUNTRY = 'SET_USER_COUNTRY',
	DEDUCT_CREDITS = 'DEDUCT_CREDITS',
	SET_IS_FETCHING_CART_ITEMS = 'SET_IS_FETCHING_CART_ITEMS',
	SET_CART_ITEMS = 'SET_CART_ITEMS',
	SET_CART_ITEMS_ID = 'SET_CART_ITEMS_ID',
	ADD_CART_ITEMS_ID = 'ADD_CART_ITEMS_ID',
	REMOVE_CART_ITEMS_ID = 'REMOVE_CART_ITEMS_ID',
	CLEAR_CART_ITEMS = 'CLEAR_CART_ITEMS',
	SET_SHOW_CART_PAYMENT_METHOD_MODAL = 'SET_SHOW_CART_PAYMENT_METHOD_MODAL',
	SET_SHOW_CART_PAYMENT_STATUS_POPOVER_MODAL = 'SET_SHOW_CART_PAYMENT_STATUS_POPOVER_MODAL',
	// Purchase History
	SET_IS_FETCHING_PURCHASE_ITEMS = 'SET_IS_FETCHING_PURCHASE_ITEMS',
	SET_PURCHASED_ITEMS = 'SET_PURCHASED_ITEMS',
	SET_PURCHASED_ITEMS_FILTER = 'SET_PURCHASED_ITEMS_FILTER',
	RESET_PURCHASED_ITEMS = 'RESET_PURCHASED_ITEMS',
	SET_TEMPORARY_USER_PURCHASED_HISTORY = 'SET_TEMPORARY_USER_PURCHASED_HISTORY',
}

export enum ActionTypes {
	FETCH_USER_INFO = 'FETCH_USER_INFO',
	FETCH_PERSONAL_DATA = 'FETCH_PERSONAL_DATA',
	FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION',
	FETCH_CREDITS = 'FETCH_CREDITS',
	FETCH_REFERRALS_INFO = 'FETCH_REFERRALS_INFO',
	FETCH_COUNTRY_CODE = 'FETCH_COUNTRY_CODE',
	FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
	SUBCRIBE_NOTIFICATIONS = 'SUBCRIBE_NOTIFICATIONS',
	SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE = 'SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE',
	SUBSCRIBE_USER_SESSIONS = 'SUBSCRIBE_USER_SESSIONS',
	SUBSCRIBE_CREDITS_CHANGE = 'SUBSCRIBE_CREDITS_CHANGE',
	SUBSCRIBE_REWARDS_CHANGE = 'SUBSCRIBE_REWARDS_CHANGE',
	CHANGE_AVATAR = 'CHANGE_AVATAR',
	FETCH_TUTORIALS = 'FETCH_TUTORIALS',
	UPDATE_PERSONAL_DATA = 'UPDATE_PERSONAL_DATA',
	RENEW_SUBSCRIPTION = 'RENEW_SUBSCRIPTION',
	CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
	ADD_CANCEL_SUBSCRIPTION_REASON = 'ADD_CANCEL_SUBSCRIPTION_REASON',
	FETCH_SUBSCRIPTION_PAYMENT_PORTAL = 'FETCH_SUBSCRIPTION_PAYMENT_PORTAL',
	FETCH_CUSTOMER_PORTAL = 'FETCH_CUSTOMER_PORTAL',
	FETCH_INVOICES_PORTAL = 'FETCH_INVOICES_PORTAL',
	FETCH_TOP_UP_CREDITS_PORTAL = 'FETCH_TOP_UP_CREDITS_PORTAL',
	FETCH_CART_CHECKOUT_PORTAL = 'FETCH_CART_CHECKOUT_PORTAL',
	CONTACT_US = 'CONTACT_US',
	FETCH_EMAIL_NOTIFICATIONS = 'FETCH_EMAIL_NOTIFICATIONS',
	UPDATE_EMAIL_NOTIFICATION = 'UPDATE_EMAIL_NOTIFICATION',
	FETCH_CART_ITEMS = 'FETCH_CART_ITEMS',
	FETCH_PURCHASED_ITEMS = 'FETCH_PURCHASED_ITEMS',
	FETCH_PRESIGNED_PURCHASED_ITEM = 'FETCH_PRESIGNED_PURCHASED_ITEM',
}
